import { notification } from 'uikit';
import axiosInstance from './axiosInstance.js';

const requestManager = (url, method, data=null, callback=(res) => res, notifications=null) => {

    if (method === 'GET') {
        axiosInstance.get(url).then(res => {
            displayNotif(notifications, res.status);
            return (res.status === 200 ? callback(res.data) : callback(false));
        }).catch(err => {
            return errorCallback(notifications, err, callback);
        });
    }

    if (method === 'POST') {
        axiosInstance.post(url, data).then(res => {
            
            displayNotif(notifications, res.status);
            return (res.status === 200 ? callback(res.data) : callback(false));
        }).catch(err => {
            return errorCallback(notifications, err, callback);
        });
    }

    if (method === 'PUT') {
        axiosInstance.put(url, data).then(res => {
            
            displayNotif(notifications, res.status);
            return (res.status === 200 ? callback(true) : callback(false));
        }).catch(err => {
            return errorCallback(notifications, err, callback);
        });
    }

    if (method === 'DELETE') {
        axiosInstance.delete(url).then(res => {
            
            displayNotif(notifications, res.status);
            return (res.status === 200 ? callback(res.data) : callback(false));
        }).catch(err => {
            return errorCallback(notifications, err, callback);
        });
    }

    const errorCallback = (notifications, err, callback) => {
        displayNotif(notifications, (err.response && err.response.status ? err.response.status : "timeout"));
        return callback(false);
    };

    const displayNotif = (notifications, status) => {
        if ((notifications && status in notifications) || status === "timeout") {

            let msg = null;

            if (status === "timeout") {
                msg = "La requête a échouée<br />Délai d'attente dépassé";
            }
            else {
                msg = notifications[status];
            }

            notification({
                message: '<p style="font-size: 0.8em; margin: 0;">'+msg+'</p>',
                status: status === 200 ? 'success' : 'danger',
                pos: 'top-center',
                timeout: 6000
            });
        }
    }
}

export default requestManager;