import { Link } from 'react-router-dom';

function Nav (props) {

    const { user } = props;

    return (<nav className="uk-navbar-container" data-uk-navbar="dropbar: true">
        <div className="uk-navbar-left"> 
            <ul className="uk-navbar-nav">
                <li>
                    <Link to={`/${window.location.search}`}>
                        <img className="mc-logo" src="images/logo_silicom_group.svg"/>
                        <h1>Questionnaire<br />Cybersécurité</h1>
                    </Link>
                </li>
            </ul>
        </div>
        <div className="uk-navbar-right">
            <ul className="uk-navbar-nav">
                <li>
                    <a href="https://security.silicom.group/" style={{padding: 0}} target="_blank">
                        <span style={{padding: '0 15px', borderRight: '1px solid #000'}}>Cybersécurité</span>
                    </a>
                </li>
                <li>
                    <a href="https://silicom.group/" style={{padding: 0}} target="_blank">
                        <span style={{padding: '0 15px'}}>Silicom Group</span>
                    </a>
                </li>
            </ul>
        </div>
    </nav>);
}

export default Nav;
