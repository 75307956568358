import axios from 'axios';

let headers = {
    'Accept': 'application/json', 
    'Content-Type': 'application/json'
};

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: headers
});

instance.interceptors.request.use(function(config) {
    const token = localStorage.getItem('silicom_survey_token');
    
    if (token !== null) {
        config.headers.Token = token;
    }

    return config;
});

export default instance;