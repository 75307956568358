

function Footer () {

    return (<div className="mc-footer uk-text-center uk-padding-large">
        © Silicom Group {new Date().getFullYear()}
    </div>);
}

export default Footer;
