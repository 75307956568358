const survey = {
    1: {
        name: "Identifier",
        icon: "view",
        score: 0,
        sections: {
            1: {
                name: "Connaissez-vous votre environnement informatique",
                questions: {
                    1: {
                        description: "J'ai un inventaire de mes équipements informatiques",
                        advice: "Etablir un inventaire des équipements",
                        points: 0.2,
                        weight: 1,
                        answer: false
                    },
                    2: {
                        description: "Je connais les services et produits informatiques que mon entreprise utilise",
                        advice: "Etablir un inventaire des solutions et produits",
                        points: 0.2,
                        weight: 15,
                        answer: false
                    },
                    3: {
                        description: "Un responsable de l'informatique a été nommé",
                        advice: "Nommer une personne responsable de l'IT ou responsable des relations avec le prestataire IT",
                        points: 0.4,
                        weight: 5,
                        answer: false
                    },
                    4: {
                        description: "J'ai un inventaire automatisé de mes équipements et logiciels informatiques",
                        advice: "Mettre en place une solution automatisée d'inventaire",
                        points: 0.4,
                        weight: 40,
                        answer: false
                    }
                }
            },
            2: {
                name: "Connaissez-vous les contraintes légales liées à l'utilisation d'outils informatique",
                questions: {
                    1: {
                        description: "J'ai un registre de traitement des données personnelles ou sensibles",
                        advice: "Etablir une cartographie des données personnelles stockées dans l'entreprise",
                        points: 0.4,
                        weight: 6,
                        answer: false
                    },
                    2: {
                        description: "J'ai effectué une analyse de risques concernant la protection des données",
                        advice: "Procéder à une analyse de risques sur la base de l'inventaire des données personnelles traitées",
                        points: 0.4,
                        weight: 16,
                        answer: false
                    },
                    3: {
                        description: "J'ai effectué une analyse de risques concernant la continuité de mon business",
                        advice: "Procéder à une Business Impact Analysis afin de déterminer les activités essentielles de mon entreprise",
                        points: 0.4,
                        weight: 28,
                        answer: false
                    },
                    4: {
                        description: "J'ai un règlement de l'utilisation des moyens informatiques et d'Internet",
                        advice: "Etablir une charte d'utilisation des moyens informatiques",
                        points: 0.2,
                        weight: 4,
                        answer: false
                    },
                    5: {
                        description: "J'ai des contrats avec mes fournisseurs informatiques pour garantir la continuité de mon business",
                        advice: "Formaliser des contrats avec des niveaux de services définis avec mes fournisseurs IT",
                        points: 0.4,
                        weight: 25,
                        answer: false
                    },
                    6: {
                        description: "J'ai identifié les lois auxquelles je suis soumis",
                        advice: "Etablir le registre de lois auxquelles ma société est soumise",
                        points: 0.2,
                        weight: 26,
                        answer: false
                    },
                    7: {
                        description: "Je connais mes responsabilités, celles de mes fournisseurs, celles de mes hébergeurs",
                        advice: "Etablir une matrice de responsabilités des intervenants et utilisateurs du Système d'information afin d'éviter le scénario: « Je pensais que c'était vous qui vous en chargiez »",
                        points: 0.2,
                        weight: 10,
                        answer: false
                    }
                }
            },
            3: {
                name: "Connaissez-vous les limites de votre système informatique",
                questions: {
                    1: {
                        description: "Je procède à des audits réguliers de mon environnement informatique",
                        advice: "Procéder à l'analyse de votre Système par une personne externe de vos infrastrucutures et de vos fournisseurs",
                        points: 0.4,
                        weight: 11,
                        answer: false
                    },
                    2: {
                        description: "Je possède des procédures d'alarme en cas d'événement suspect ",
                        advice: "Etablir et distribuer des procédures claires afin de réagir correctement à un incident informatique",
                        points: 0.4,
                        weight: 22,
                        answer: false
                    },
                    3: {
                        description: "J'ai une procédure d'arrivées et de départs des collaborateurs",
                        advice: "Etablir des listes de contrôles pour les engagements et les départs",
                        points: 0.2,
                        weight: 23,
                        answer: false
                    },
                    4: {
                        description: "J'ai un inventaire des contrats informatiques, des contrats de maintenance et de leurs échéances",
                        advice: "Maintenir un calendrier des contrats informatiques avec leurs échéances",
                        points: 0.4,
                        weight: 12,
                        answer: false
                    },
                    5: {
                        description: "J'ai demandé à procéder à des tests de sécurité sur mes systèmes exposés (Site Web, eCommerce, ...)",
                        advice: "Faire tester régulièrement la sécurité de vos systèmes directements exposés sur Internet (Site Web, eCommerce, …)",
                        points: 0.4,
                        weight: 3,
                        answer: false
                    }
                }
            }
        }
    },
    2: {
        name: "Protéger",
        icon: "lock",
        score: 0,
        sections: {
            1: {
                name: "Comment gérez-vous les accès aux outils informatiques et aux données",
                questions: {
                    1: {
                        description: "Les comptes d'accès privilégiés (administrateur) sont différents des comptes de bureautique standard",
                        advice: "Etablir une séparation des comptes à privilège (administration du SI) et les comptes utilisateurs",
                        points: 1,
                        weight: 8,
                        answer: false
                    },
                    2: {
                        description: "Les données personnelles, sensibles ou confidentielles sont protégées par des droits d'accès",
                        advice: "Mettre en place des systèmes de droit limitant l'accès aux données en fonction du rôle de la personne",
                        points: 0.5,
                        weight: 9,
                        answer: false
                    },
                    3: {
                        description: "Des solutions à double authentification (mot de passe + SMS, ou mot de passe + application de validation, …) sont utilisés pour protéger tous nos accès accessibles depuis l'extérieur (VPN, Office 365, …)",
                        advice: "Mettre en place des solutions à double facteur de type SMS ou application mobile pour vérifier l'identité des personnes",
                        points: 1,
                        weight: 13,
                        answer: false
                    },
                    4: {
                        description: "Nos équipements mobiles sont protégés contre le vol ou l'oubli (Cryptage, solution anti-vol)",
                        advice: "Chiffrer les disques des équipements ainsi que les supports amovibles tels que des clés USB",
                        points: 0.5,
                        weight: 42,
                        answer: false
                    }
                }
            },
            2: {
                name: "Quelles sont les mesures générales pour se protéger des dangers d'Internet",
                questions: {
                    1: {
                        description: "Les collaborateurs sont formés et sensibilisés aux dangers d'Internet",
                        advice: "Sensibiliser et former régulièrement vos collaborateurs sur les risques liés aux outils informatiques et le social engineering",
                        points: 0.5,
                        weight: 14,
                        answer: false
                    },
                    2: {
                        description: "J'ai une solution de filtrage du trafic internet",
                        advice: "Limiter l'accès aux sites à risques et éliminer les menaces avant qu'elles n'atteignent vos périphériques",
                        points: 0.3,
                        weight: 17,
                        answer: false
                    },
                    3: {
                        description: "Les outils sont régulièrement mis à jour et suivent les recommandations de sécurité des fournisseurs",
                        advice: "Planifier et contrôler que tous vos équipements sont régulièrement mis à jour pour limiter les vulnérabilités",
                        points: 0.5,
                        weight: 18,
                        answer: false
                    },
                    4: {
                        description: "Un règlement de gestion des mots de passe existe et il est appliqué dans tous les outils utilisés",
                        advice: "Mettre en place une politique de mot de passe forte et encourager des mots de passe uniques",
                        points: 0.3,
                        weight: 19,
                        answer: false
                    },
                    5: {
                        description: "Le wifi n'est pas disponible pour le public et est protégé par un mot de passe fort ou lié à mon annuaire d'entreprise",
                        advice: "Segmenter les accès de vos visiteurs et de votre réseau",
                        points: 0.3,
                        weight: 20,
                        answer: false
                    },
                    6: {
                        description: "Des tests de hameçonnage (Phishing) sont régulièrement organisés",
                        advice: "Tester le niveau de maturité cyber de vos collaborateurs",
                        points: 0.1,
                        weight: 21,
                        answer: false
                    }
                }
            },
            3: {
                name: "Savez-vous où sont vos données ?",
                questions: {
                    1: {
                        description: "Mes données sont stockées en Suisse, sous contrat de droit Suisse",
                        advice: "Un stockage en Suisse, sous droit Suisse peut limiter certains risques de fuite de données et réduire l'exposition à certaines lois étrangères",
                        points: 0.1,
                        weight: 39,
                        answer: false
                    },
                    2: {
                        description: "Mes données d'entreprise sont sauvegardées sur un site tiers",
                        advice: "Un stockage des sauvegardes sur un site déconnecté externe présente une meilleure garantie de reprise d'activité en cas de désastre majeur",
                        points: 0.5,
                        weight: 24,
                        answer: false
                    },
                    3: {
                        description: "L'utilisation des supports amovibles (clé USB) ou des solutions Cloud sont réglementées",
                        advice: "Limiter l'usage de supports amovibles ou cadrer-le afin de garder la maitrise de vos données",
                        points: 0.1,
                        weight: 36,
                        answer: false
                    },
                    4: {
                        description: "Je possède une assurance cyber couvrant certains frais en cas d'incident ",
                        advice: "La conclusion d'un contrat d'assurance cyber vous permet dans certains cas de limiter l'impact financier d'une crise informatique",
                        points: 0.2,
                        weight: 43,
                        answer: false
                    },
                }
            }
        }
    },
    3: {
        name: "Détecter",
        icon: "microscope",
        score: 0,
        sections: {
            1: {
                name: "Comment détecter-vous les menaces attaquant votre environnement informatique",
                questions: {
                    1: {
                        description: "Tous nos postes sont équipés d'anti-virus",
                        advice: "Mettre en place un  anti-virus sur les postes de travail pour détecter les menaces",
                        points: 1,
                        weight: 2,
                        answer: false
                    },
                    2: {
                        description: "Notre messagerie est équipe de filtres anti-spam et anti-virus",
                        advice: "Mettre en place un  filtre anti-spam efficace pour réduire le risque de social engineering",
                        points: 1,
                        weight: 7,
                        answer: false
                    },
                    3: {
                        description: "Une personne de contact lors d'un problème informatique est définie et connue de tous dans l'entreprise",
                        advice: "Mettre en place une pocédure claire pour signaler rapidement un incident informatique",
                        points: 1,
                        weight: 27,
                        answer: false
                    },
                    4: {
                        description: "Une société externe est en charge de surveiller la sécurité de notre système informatique",
                        advice: "Faire appel à des solutions managées de surveillance et de défense améliore la détection d'incident et sa réponse",
                        points: 1,
                        weight: 30,
                        answer: false
                    },
                    5: {
                        description: "Un collaborateur exposé à une menace est encouragé à la signaler et ne subira pas de sanctions (hormis fautes à répétition)",
                        advice: "Informer les collaborateurs qu'ils doivent signaler un incident et les féliciter pour la démarche à la place de les réprimander réduit le temps de détection d'un incident",
                        points: 1,
                        weight: 29,
                        answer: false
                    },
                }
            }
        }
    },
    4: {
        name: "Réagir",
        icon: "alarm",
        score: 0,
        sections: {
            1: {
                name: "Comment sont gérées les crises informatiques",
                questions: {
                    1: {
                        description: "Des processus d'urgence sont définis en cas de virus informatique ou de vol de données",
                        advice: "Etablir un plan de gestion de crise informatique",
                        points: 1,
                        weight: 31,
                        answer: false
                    },
                    2: {
                        description: "Un plan de reprise est défini et des moyens alternatifs pour continuer le travail sont prévus",
                        advice: "Etablir un plan de reprise informatique",
                        points: 1,
                        weight: 35,
                        answer: false
                    },
                    3: {
                        description: "Un plan de communication de crise interne et externe est prévu",
                        advice: "Etablir un plan de communication de crise",
                        points: 1,
                        weight: 37,
                        answer: false
                    },
                    4: {
                        description: "Le plan de reprise est testé et connu des personnes impliquées",
                        advice: "Pratiquer au moins une fois un exercice de gestion de crise avec reprise de service",
                        points: 1,
                        weight: 38,
                        answer: false
                    },
                    5: {
                        description: "Tout le personnel connaît ses responsabilités et ses limites en cas d'incident ou de demande liés à la protection des données",
                        advice: "Informer les personnes impliquées dans le traitement des données de leur responsabilité",
                        points: 1,
                        weight: 32,
                        answer: false
                    }
                }
            }
        }
    },
    5: {
        name: "Récupérer",
        icon: "folder",
        score: 0,
        sections: {
            1: {
                name: "Comment récupérer mon système informatique lors d'une crise",
                questions: {
                    1: {
                        description: "Le processus de restauration des données est vérifié régulièrement",
                        advice: "Pratiquer une fois par année un exercice de restauration de données",
                        points: 1.66,
                        weight: 33,
                        answer: false
                    },
                    2: {
                        description: "Une culture d'amélioration continue du SI existe",
                        advice: "La direction doit piloter la mise en place des processus d'amélioration",
                        points: 1.66,
                        weight: 34,
                        answer: false
                    },
                    3: {
                        description: "Une veille est effectuée sur les nouveautés par moi-même ou mon fournisseur de service",
                        advice: "Définir un responsable de la veille sécuritaire",
                        points: 1.66,
                        weight: 41,
                        answer: false
                    }
                }
            }
        }
    }
}

export default survey;