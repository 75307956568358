import userEvent from '@testing-library/user-event';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Radar } from 'react-chartjs-2';
import requestManager from '../utils/requestManager.js';

function Score(props) {

    const { survey, user, setUser, token, setToken, hasAnswered } = props;

    const [saving, setSaving] = useState(true);
    const [updating, setUpdating] = useState(null);
    const [answers, setAnswers] = useState({});

    const segments = [
        "Administration publique",
        "Commerce",
        "Construction",
        "Courtier en assurances",
        "Industrie",
        "Santé humaine et action sociale",
        "Service aux entreprises",
        "Service industriel",
        "Société de services",
        "Autre"
    ];

    const societySizes = [
        "0 - 9",
        "10 - 49",
        "50 - 199",
        "> 200"
    ]

    const counties = [
        "Appenzell Rh.-Ext",
        "Appenzell Rh.-Int.",
        "Argovie",
        "Bâle-Campagne",
        "Bâle-Ville",
        "Berne",
        "Fribourg",
        "Genève",
        "Glaris",
        "Grisons",
        "Jura",
        "Lucerne",
        "Neuchâtel",
        "Nidwald",
        "Obwald",
        "Saint-Gall",
        "Schaffhouse",
        "Schwytz",
        "Soleure",
        "Tessin",
        "Thurgovie",
        "Uri",
        "Valais",
        "Vaud",
        "Zoug",
        "Zurich"
    ]

    useEffect(() => {
        if (hasAnswered) {
            requestManager('/save-survey', (token === null ? 'POST' : 'PUT'), {survey: survey},
                (res) => {
                    
                    if (res && token === null) {
                        setToken(res);
                    }
                    
                    setSaving(false);
                }
            );

            const answersUpdate = [];
            Object.keys(survey).map((categoryKey) => {
                Object.keys(survey[categoryKey].sections).map(sectionKey => {
                    Object.keys(survey[categoryKey].sections[sectionKey].questions).map(questionKey => {
                        const question = survey[categoryKey].sections[sectionKey].questions[questionKey];
                        if (!question.answer) {
                            answersUpdate.push(question);
                        }
                    });
                });
            });

            answersUpdate.sort(sortByWeight);
            setAnswers(answersUpdate.slice(0, 5));
        }
        
    }, []);

    const sortByWeight = (a, b) => {
        if (a.weight > b.weight) return 1;
        if (b.weight > a.weight) return -1;
        return 0;
    }

    const handleChangeUser = (e) => {
        setUser({...user, [e.target.name]: e.target.value});
    }

    const handleChangeUserSelect = (e) => {
        setUser({...user, [e.target.name]: e.target.value});
    }

    const handleChangeUserRadio = (e) => {
        setUser({...user, [e.target.name]: (e.target.value === 'true' ? true : false)});
    }

    const handleNewsletterRegister = (e) => {
        e.preventDefault();
        
        setUpdating('newsletter');
        requestManager('/newsletter-register', 'PUT', {user: user},
            (res) => {
                setUpdating(null);
            },
            {
                200: "Vous êtes désormais inscrit à la newsletter de Silicom Group",
                400: "Un problème est survenu lors de l'inscription à la newsletter",
                500: "Un problème est survenu lors de l'inscription à la newsletter"
            }
        );
    }

    const handleSendResult = (e) => {
        e.preventDefault();

        setUpdating('send');
        requestManager('/send-mail', 'PUT', {user: user, survey: survey, answers: answers},
            (res) => {
                setUpdating(null);
            },
            {
                200: "Le résultat de votre test vous a bien été envoyé",
                400: "Un problème est survenu lors de l'envoi de l'e-mail",
                500: "Un problème est survenu lors de l'envoi de l'e-mail"
            }
        );
    }

    const handleAskStudy = (e) => {
        e.preventDefault();
        
        setUpdating('study');
        requestManager('/ask-study', 'PUT', {user: user, survey: survey, answers: answers},
            (res) => {
                setUpdating(null);
            },
            {
                200: "Une demande de comparaison a bien été envoyée",
                400: "Un problème est survenu lors de la demande de comparaison",
                500: "Un problème est survenu lors de la demande de comparaison"
            }
        );
    }
    
    const handleAskContact = (e) => {
        e.preventDefault();
        
        setUpdating('contact');
        requestManager('/ask-contact', 'PUT', {user: user, survey: survey, answers: answers},
            (res) => {
                setUpdating(null);
            },
            {
                200: "Une demande de contact a bien été envoyée",
                400: "Un problème est survenu lors de la demande de contact",
                500: "Un problème est survenu lors de la demande de contact"
            }
        );
    }

    return (<div className="uk-container">
        <div className="uk-grid mc-widgets">
            {hasAnswered === false ? <div className="uk-width-1-1@m">
                <div className="mc-widget uk-text-center" style={{cursor: 'default'}}>
                    <p>Veuillez répondre au questionnaire avant d'afficher les résultats.</p>
                    <Link to="/" className="uk-button" style={{marginTop: '20px'}}>
                        Questionnaire
                    </Link>
                </div>
            </div> : saving === true ? <div className="uk-width-1-1@m">
                <div className="mc-widget uk-text-center" style={{cursor: 'default'}}>
                    <div uk-spinner={''}></div>
                </div>
            </div> : <>
            <div className="uk-width-1-1@m">
                <div className="mc-widget" style={{cursor: 'default'}}>
                    <span className="mc-icon flaticon-medal"></span>
                    <h4 className="uk-text-center">Résultats</h4>
                    <p className="uk-text-center" style={{marginBottom: '40px'}}>
                        Merci d'avoir répondu à toutes nos questions concernant la cybersécurité au sein de votre structure.<br />
                        Grâce à elles, découvrez une estimation de votre maturité cyber sur ce graphique basé sur les recommandations du NIST.
                    </p>

                    <Radar redraw={true} data={{
                        labels: Object.keys(survey).map((categoryKey) => (survey[categoryKey].name)),
                        datasets: [{
                            backgroundColor: 'rgba(255,0,0,0.3)',
                            pointBackgroundColor: '#f00',
                            borderWidth: 0,
                            pointBorderWidth: 0,
                            data: Object.keys(survey).map((categoryKey) => (survey[categoryKey].score > 5 ? 5 : survey[categoryKey].score))
                        }]
                    }}
                    options={{
                        legend: {
                            display: false
                        },
                        tooltips: {
                            enabled: false
                        },
                        scale: {
                            ticks: {
                                beginAtZero: true,
                                min: 0,
                                max: 5
                            },
                            pointLabels: {
                                fontSize: 14
                            }
                        }
                    }} />
                </div>
            </div>

            {/* Score table
            <div className="uk-width-1-2@m">
                <div className="mc-widget" style={{cursor: 'default'}}>

                    <table className="uk-table uk-table-divider"><tbody>
                        {Object.keys(survey).map((categoryKey) => (
                            <tr key={categoryKey}>
                                <td>{survey[categoryKey].name}</td>
                                <td className="uk-text-right">{Math.round(survey[categoryKey].score.toFixed(2)*100)}</td>
                            </tr>
                        ))}

                        <tr>
                            <td><strong>Total</strong></td>
                            <td className="uk-text-right">
                                <strong>{Math.round(Object.keys(survey).map((categoryKey) => (survey[categoryKey].score)).reduce((total, score) => (total + score)).toFixed(2)*100)}</strong>
                            </td>
                        </tr>
                    </tbody></table>
                </div>
            </div>

            <div className="uk-width-1-1@m">
                <div className="mc-widget" style={{cursor: 'default'}}>
                    
                    <Radar redraw={true} data={{
                        labels: Object.keys(survey).map((categoryKey) => (survey[categoryKey].name)),
                        datasets: [{
                            backgroundColor: 'rgba(255,0,0,0.3)',
                            pointBackgroundColor: '#f00',
                            borderWidth: 0,
                            pointBorderWidth: 0,
                            data: Object.keys(survey).map((categoryKey) => (survey[categoryKey].score > 5 ? 5 : survey[categoryKey].score))
                        }]
                    }}
                    options={{
                        legend: {display: false},
                        tooltips: {
                            enabled: false
                        },
                        scale: {
                            ticks: {
                                beginAtZero: true,
                                max: 5
                            }
                        }
                    }} />
                </div>
            </div>*/}

            <div className="uk-width-1-1@m">
                <div className="mc-widget" style={{cursor: 'default'}}>
                    <h4 className="uk-text-center">Actions à entreprendre</h4>
                    <p className="uk-text-center" style={{marginBottom: '40px'}}>
                        Sur la base des réponses données, découvrez nos premières recommandations pour réduire votre risque cyber.<br />
                        Néanmoins nous vous conseillons de faire appel à un professionnel pour analyser votre environnement et vous accompagner dans cette démarche.
                    </p>
                    
                    <ul className="uk-list uk-list-divider uk-list-decimal uk-list-large">
                        {answers.length ? answers.map((question, questionKey) => (<li key={questionKey}>{question.advice}</li>)) : <li>Il n'y a aucune action à entreprendre</li>}
                    </ul>
                    
                </div>
            </div>

            {/* Actions to do
            <div className="uk-width-1-1@m">
                <div className="mc-widget" style={{cursor: 'default'}}>
                    <h4 className="uk-text-center">Actions à entreprendre</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quis pharetra diam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Integer ut varius lectus, facilisis mollis tellus. Pellentesque sed lectus non nunc hendrerit varius sed et justo. Praesent tellus mauris, imperdiet eget cursus eget, facilisis ut eros. Cras dignissim magna a tortor ullamcorper, sit amet luctus tortor sollicitudin. Nam rhoncus risus quis efficitur varius. Nullam ac consequat eros, sed tincidunt risus. Sed blandit magna in euismod facilisis. Praesent commodo a est id euismod. Mauris vel scelerisque turpis.</p>
                
                    <div className="uk-grid" style={{marginLeft: 0, marginTop: '40px'}}>
                        <ul uk-tab={""} className="uk-width-1-4@l uk-width-1-3@m uk-tab-left uk-text-center" style={{marginBottom: '20px'}}>
                            
                            {Object.keys(survey).map((categoryKey, pos) => (<li key={categoryKey}>
                                <a href="#">
                                    {survey[categoryKey].name}
                                </a>
                            </li>))}
                            
                        </ul>
                        <ul className="uk-width-3-4@l uk-width-2-3@m uk-switcher">
                            {Object.keys(survey).map((categoryKey, pos) => {
                                let questions = [];
                                Object.keys(survey[categoryKey].sections).map(sectionKey => {
                                    Object.keys(survey[categoryKey].sections[sectionKey].questions).map(questionKey => {
                                        const question = survey[categoryKey].sections[sectionKey].questions[questionKey];
                                        if (!question.answer) {
                                            questions.push(question);
                                        }
                                    });
                                });
                                
                                questions.sort(sortByWeight);
                                questions = questions.slice(0, 5);
                                
                                return (<li key={categoryKey}>
                                    <dl className="uk-description-list uk-description-list-divider">
                                        {questions.length ? questions.map((question, questionKey) => (
                                            <React.Fragment key={questionKey}>
                                                <dt style={{color: 'rgba(255, 0, 0, 0.8)'}}>{question.description}</dt>
                                                <dd>{question.advice}</dd>
                                            </React.Fragment>)) : 
                                            <>
                                                <dt style={{color: 'rgba(0, 155, 0, 0.8)'}}>Il n'y a aucune action à entreprendre</dt>
                                                <dd>Votre score est bon dans cette catégorie</dd>
                                            </>
                                        }
                                </dl>
                                </li>);
                            })}
                        </ul>
                    </div>
                </div>
            </div>*/}

            <div className="uk-width-1-2@m">
                <div className="mc-widget" style={{cursor: 'default', overflow: 'hidden'}}>
                    <h4 className="uk-text-center">S'inscrire à notre newsletter</h4>
                    <p>Si vous souhaitez recevoir notre newsletter Silicom Group, veuillez nous transmettre votre adresse e-mail.</p>
                    <form onSubmit={handleNewsletterRegister}>
                        <label className="uk-form-label">Adresse E-mail</label>
                        <input name="email" type="email" className="uk-input" value={user.email} placeholder="abc@def.xyz" onChange={handleChangeUser} required/>

                        <button type="submit" className={"uk-button "+(updating !== null ? " uk-disabled" : "")} style={{float: 'right', marginTop: '20px'}} disabled={(updating === null ? false : true)}>
                            S'inscrire {updating === 'newsletter' ? <div uk-spinner={'ratio: .5'}></div> : null}
                        </button>
                    </form>
                </div>
            </div>

            <div className="uk-width-1-2@m">
                <div className="mc-widget" style={{cursor: 'default', overflow: 'hidden'}}>
                    <h4 className="uk-text-center">Recevoir votre résultat par E-mail</h4>
                    <p>Si vous souhaitez recevoir le résultat de ce questionnaire, ainsi que vos réponses, veuillez nous transmettre votre e-mail.</p>
                    <form onSubmit={handleSendResult}>
                        <label className="uk-form-label">Adresse E-mail</label>
                        <input name="email" type="email" className="uk-input" value={user.email} placeholder="abc@def.xyz" onChange={handleChangeUser} required/>

                        <button type="submit" className={"uk-button "+(updating !== null ? " uk-disabled" : "")} style={{float: 'right', marginTop: '20px'}} disabled={(updating === null ? false : true)}>
                            Envoyer {updating === 'send' ? <div uk-spinner={'ratio: .5'}></div> : null}
                        </button>
                    </form>
                </div>
            </div>

            <div className="uk-width-1-2@m">
                <div className="mc-widget" style={{cursor: 'default', overflow: 'hidden'}}>
                    <h4 className="uk-text-center">Demander une étude comparative</h4>
                    <p>Suite à votre résultat, vous souhaiteriez avoir une comparaison votre cyber maturité avec des entreprises de votre secteur d'activités et de votre taille ? En remplissant ce formulaire, une réponse personnalisée vous sera faite dans les plus brefs délais par notre équipe d'experts.</p>
                    <form onSubmit={handleAskStudy}>
                        <label className="uk-form-label">Adresse E-mail</label>
                        <input name="email" type="email" className="uk-input" value={user.email} placeholder="abc@def.xyz" onChange={handleChangeUser} required/>

                        <label className="uk-form-label">Segment de l'entreprise</label>
                        <select name="society_segment" className="uk-select" defaultValue={user.society_segment} onChange={handleChangeUserSelect} required>
                            <option value={""} disabled={true}>Choisir un segment</option>
                            {segments.map(segment => (<option key={segment} value={segment}>{segment}</option>))}
                        </select>
                        <input type="text" 
                            name="society_segment_other" 
                            className="uk-textarea" 
                            value={user.society_segment_other} 
                            onChange={handleChangeUser} 
                            style={{...(user.society_segment !== 'Autre' ? {display: 'none'} : null), marginTop: '15px'}}
                            placeholder="Veuillez préciser" 
                            required={user.society_segment === 'Autre' ? true : false}
                        />

                        <label className="uk-form-label">Taille de l'entreprise</label>
                        <select name="society_size" className="uk-select" defaultValue={user.society_size} onChange={handleChangeUserSelect} required>
                            <option value={""} disabled={true}>Choisir une taille</option>
                            {societySizes.map(societySize => (<option key={societySize} value={societySize}>{societySize}</option>))}
                        </select>

                        <div style={{padding: '10px', marginTop: '20px', border: '1px solid #e5e5e5'}}>
                            <label className="uk-form-label" style={{display: 'inline'}}>Vente en ligne ? </label>
                            <span style={{float: 'right'}}>
                                <label><input name="ecommerce" className="uk-radio" type="radio" value={true} checked={user.ecommerce ? true : false} onChange={handleChangeUserRadio} required/> Oui </label>
                                <label><input name="ecommerce" className="uk-radio" type="radio" value={false} checked={(!user.ecommerce && user.ecommerce !== null) ? true : false} onChange={handleChangeUserRadio} required/> Non</label>
                            </span>
                        </div>

                        <button type="submit" className={"uk-button "+(updating !== null ? " uk-disabled" : "")} style={{float: 'right', marginTop: '20px'}} disabled={(updating === null ? false : true)}>
                            Comparer {updating === 'study' ? <div uk-spinner={'ratio: .5'}></div> : null}
                        </button>
                    </form>
                </div>
            </div>

            <div className="uk-width-1-2@m">
                <div className="mc-widget" style={{cursor: 'default', overflow: 'hidden'}}>
                    <h4 className="uk-text-center">J'aimerais être contacté</h4>
                    <p>Si vous souhaitez qu'un de nos experts prenne contact avec vous, veuillez remplir ce formulaire.</p>

                    <form onSubmit={handleAskContact}>
                        <label className="uk-form-label">Adresse E-mail</label>
                        <input name="email" type="email" className="uk-input" value={user.email} placeholder="abc@def.xyz" onChange={handleChangeUser} required/>

                        <label className="uk-form-label">Prénom</label>
                        <input name="firstname" type="text" className="uk-input" value={user.firstname} placeholder="Vincent" onChange={handleChangeUser} required/>

                        <label className="uk-form-label">Nom de famille</label>
                        <input name="lastname" type="text" className="uk-input" value={user.lastname} placeholder="Rochat" onChange={handleChangeUser} required/>

                        <label className="uk-form-label">Nom de l'entreprise</label>
                        <input name="society_name" type="text" className="uk-input" value={user.society_name} placeholder="Silicom Group" onChange={handleChangeUser} required/>

                        <label className="uk-form-label">Canton de l'entreprise</label>
                        <select name="society_county" className="uk-select" defaultValue={user.society_county} onChange={handleChangeUserSelect} required>
                            <option value={""} disabled={true}>Choisir un canton</option>
                            {counties.map(county => (<option key={county} value={county}>{county}</option>))}
                        </select>

                        <label className="uk-form-label">Remarques</label>
                        <textarea name="remarks" className="uk-textarea" defaultValue={user.remarks} onChange={handleChangeUser}></textarea>
                        
                        <button type="submit" className={"uk-button "+(updating !== null ? " uk-disabled" : "")} style={{float: 'right', marginTop: '20px'}} disabled={(updating === null ? false : true)}>
                            Rencontrer {updating === 'contact' ? <div uk-spinner={'ratio: .5'}></div> : null}
                        </button>
                    </form>
                </div>
            </div>
            </>}
        </div>
        
    </div>);
}

export default Score;