import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import requestManager from './utils/requestManager.js';

import Nav from'./components/Nav.js';
import Header from'./components/Header.js';
import Footer from'./components/Footer.js';
import Survey from'./screens/Survey.js';
import Score from'./screens/Score.js';
import NotFound from'./screens/NotFound.js';
import surveyObj from './survey.js';

function App() {

	const [loading, setLoading] = useState(true);
	const [token, setToken] = useState(localStorage.getItem('silicom_survey_token'));
	const [user, setUser] = useState({
		firstname: '',
		lastname: '',
		email: '',
		society_segment: '',
		society_segment_other: '',
		society_name: '',
		society_size: '',
		society_county: '',
		ecommerce: null,
		remarks: ''
	});

	const [survey, setSurvey] = useState(surveyObj);
	
	const [hasAnswered, setHasAnswered] = useState(token === null ? false : true);

	useEffect(() => {
        
		if (token) {
			requestManager('/get-survey', 'GET', null,
				(res) => {
					console.log(res);
					if (res) {
						console.log(res);
						setSurvey(res.survey);
						setUser(res.user);
					}
					else {
						localStorage.removeItem('silicom_survey_token');
						setToken(null);
					}

					setLoading(false);
				}
			);
		}
		else {
			setLoading(false);
		}
		
    }, []);

	useEffect(() => {
        
		if (token) {
			localStorage.setItem('silicom_survey_token', token);
		}
		
    }, [token]);

	return (<>
		<Router>
			<Nav />
			<Header />

			<div className="mc-content uk-animation-slide-bottom">
				{loading ? <div className="uk-container">
					<div className="uk-grid mc-widgets">
						<div className="uk-width-1-1@m">
							<div className="mc-widget uk-text-center" style={{cursor: 'default'}}>
								<div uk-spinner={''}></div>
							</div>
						</div>
					</div>
				</div> : 
				<Switch>
				
					<Route exact path="/">
						<Survey survey={survey} setSurvey={setSurvey} hasAnswered={hasAnswered} setHasAnswered={setHasAnswered} />
					</Route>

					<Route exact path="/score">
						<Score survey={survey} token={token} setToken={setToken} user={user} setUser={setUser} hasAnswered={hasAnswered} />
					</Route>

					<Route>
						<NotFound />
					</Route>
				</Switch>}

				<Footer/>

			</div>
		</Router>
	</>
	);
}

export default App;
