import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { switcher } from 'uikit';

function Survey(props) {

    const { survey, setSurvey, hasAnswered, setHasAnswered } = props;

    useEffect(() => {
        if (!hasAnswered) {
            setHasAnswered(true);
        }

    }, []);

    const handleAnswer = (categoryKey, sectionKey, questionKey) => {
        const score = survey[categoryKey].score;
        const points = survey[categoryKey].sections[sectionKey].questions[questionKey].points;

        setSurvey({
            ...survey, 
            [categoryKey]: {
                ...survey[categoryKey],
                score: (survey[categoryKey].sections[sectionKey].questions[questionKey].answer ? (score - points) : (score + points)),
                sections: {
                    ...survey[categoryKey].sections,
                    [sectionKey]: {
                        ...survey[categoryKey].sections[sectionKey],
                        questions: {
                            ...survey[categoryKey].sections[sectionKey].questions,
                            [questionKey] : {
                                ...survey[categoryKey].sections[sectionKey].questions[questionKey],
                                answer: !survey[categoryKey].sections[sectionKey].questions[questionKey].answer
                            }
                        }
                    }
                }
            }
        });

    }

    const handleNext = (pos) => {
        const tabsElem = document.querySelector('.mc-form');
        const contentElem = document.querySelector('.mc-content');

        switcher(tabsElem, {
            show: window.scrollTo({
                top: (contentElem.offsetTop - 15),
                left: 0,
                behavior: 'smooth'
            })
        }).show(pos);
    }

    return (<div className="uk-container">
        <ul uk-tab={''} className="mc-form uk-text-center">
            {Object.keys(survey).map((categoryKey, pos) => (<li key={categoryKey}>
                <a href="#">
                    <span>{pos+1}</span>
                    <br/>{survey[categoryKey].name}
                </a>
            </li>))}
            <li className="uk-disabled">
                <a href="#">
                    <span>{Object.keys(survey).length+1}</span>
                    <br/>Résultats
                </a>
            </li>
        </ul>
        <ul className="uk-switcher uk-margin">
            
            {Object.keys(survey).map((categoryKey, pos) => {

                return (<li key={categoryKey}>
                    <div className="uk-grid mc-widgets">
                        <div className="uk-width-1-1@m">
                            <div className="mc-widget" style={{paddingBottom: '120px', cursor: 'default'}}>
                                <span className={"mc-icon flaticon-"+survey[categoryKey].icon}></span>
                                <h4 className="uk-text-center">{survey[categoryKey].name}</h4>

                                {Object.keys(survey[categoryKey].sections).map((sectionKey) => {

                                    return (<div key={sectionKey}>
                                        <h5 className="uk-margin-bottom" style={{marginTop: '40px'}}>{survey[categoryKey].sections[sectionKey].name}</h5>

                                        {Object.keys(survey[categoryKey].sections[sectionKey].questions).map((questionKey) => {

                                            return (<label 
                                                key={questionKey} 
                                                className={"question"+(survey[categoryKey].sections[sectionKey].questions[questionKey].answer ? ' checked' : '')} 
                                                htmlFor={categoryKey+sectionKey+questionKey}
                                                style={{paddingLeft: '40px'}}
                                            >
                                                <input 
                                                    id={categoryKey+sectionKey+questionKey} 
                                                    type="checkbox" 
                                                    className="uk-checkbox"
                                                    checked={survey[categoryKey].sections[sectionKey].questions[questionKey].answer} 
                                                    onChange={(e) => handleAnswer(categoryKey, sectionKey, questionKey)} 
                                                    style={{marginLeft: '-31px'}}
                                                />
                                                {survey[categoryKey].sections[sectionKey].questions[questionKey].description}
                                            </label>);
                                        })}
                                    </div>);
                                })}
                                
                                {Object.keys(survey).length === (pos+1) ? 
                                <Link to={`/score${window.location.search}`} className="uk-button uk-text-right" style={{float: 'right', marginTop: '20px'}} onClick={() => window.scrollTo({top: 0, left: 0})}>
                                    Résultats
                                </Link> : 
                                <button className="uk-button uk-text-right" onClick={(e) => handleNext(pos+1)} style={{float: 'right', marginTop: '20px'}}>
                                    Suivant ({(pos+1) + '/' + Object.keys(survey).length})
                                </button>}
                            </div>
                        </div>
                    </div>
                </li>);
            })}

        </ul>
    </div>);
}

export default Survey;